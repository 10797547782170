import { useUserWishlistStore } from "@/stores/userWishlist";
import { storeToRefs } from "pinia";

import { onMounted, watch } from "vue";

export default function useWishlist() {
  const wishlist = useUserWishlistStore();

  const {
    // applications,
    // offers,
    class: listClass,
    type: listType,
    currentWishlistItems,
  } = storeToRefs(wishlist);

  const initWishlistItems = async () => {
    if (!wishlist.storeMeta.isInitialized) return;

    try {
      // if (listType.value === "offer") {
      //   initOffersAsync();
      // }

      if (listType.value === "application") {
        return await initApplicationsAsync();
      }

      throw new Error(`Wrong listType ${listType.value}!`);
    } catch (error) {
      console.log(error);
    }
  };

  // const initOffersAsync = async () => {
  //   if (listClass.value === "nanny") {
  //     // Stop if already started fetching
  //     if (offers.value.nanny.isFetching) {
  //       return;
  //     }
  //     return list.fetchOffersNanny();
  //   }
  // };

  const initApplicationsAsync = async () => {
    if (listClass.value === "nanny") {
      // Stop if already started fetching
      if (currentWishlistItems.value.isFetching) {
        return;
      }

      // TODO: change populateUserWishlist to populateUserWishlistApplicationsNanny
      wishlist.populateUserWishlist();
    }
  };

  onMounted(() => {
    initWishlistItems();
  });

  watch(listType, () => initWishlistItems());

  return { currentWishlistItems };
}
