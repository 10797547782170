<template>
  <div class="content">
    <BaseH1 :text="'Ulubione'" :short="true" />
    <ListDefaultItems
      v-if="currentWishlistItems.valueReady.length > 0"
      :list-items="currentWishlistItems.valueReady"
      :list-items-class="currentWishlistItems.class"
      :list-items-type="currentWishlistItems.type"
    />
    <SvgIllustrationsEmptySpace
      v-if="currentWishlistItems.valueReady.length === 0"
    />
    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useWishlist from "@/composables/useWishlist";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ListDefaultItems from "@/components/List/ListDefaultItems.vue";
import SvgIllustrationsEmptySpace from "@/components/Svg/Illustrations/SvgIllustrationsEmptySpace.vue";
import NavMobile from "@/components/NavMobile.vue";
import useIsMobile from "@/composables/useIsMobile";

export default {
  components: {
    ListDefaultItems,
    BaseH1,
    SvgIllustrationsEmptySpace,
    NavMobile,
  },

  setup() {
    const { isMobile } = useIsMobile();

    const { currentWishlistItems } = useWishlist();

    return {
      currentWishlistItems,
      isMobile,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  padding: 60px 0;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .content {
    padding: 30px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 30px;
  }
}
</style>
